import { Box } from "@mui/system";
import { Button } from "@mui/material";

function Cards({title, text, page, name}){
    return(
        <div className="col-12 col-md-6 col-lg-4 mb-4">
          <div className="card">
            <div className="card-body" style={{backgroundColor:'#d5e8f7'}}>
              <h5 className="card-title" style={{color:'green'}}>{title}</h5>
              <p className="card-text">{text}</p>
              <Button onClick={page} variant="contained">{name}</Button>
            </div>
          </div>
        </div>
    )
}

export default Cards;