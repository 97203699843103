import React, { useState, useEffect } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { Button, CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { config } from "../../App";
import "./EmployeeDetails.css";
import { useSelector } from "react-redux";

const EmployeeDetails = () => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { loading, siteData, siteError } = useSelector(state => state);

  let [loadings, setLoading] = useState(false);

  let [employeeData, setEmployeeData] = useState({
    siteId: "",
    siteName: "",
    empName: "",
    gender: "male",
    phone: "",
    address: "",
    birthDate: "",
    joiningDate: "",
    designation: "",
    salary: '',
    registrationDate: "",
    uan: "",
    esic: "",
    policeVer: false,
    medicalVer: false,
    iCard: false,
    aadharNo: "",
    panNo: "",
    photo: "",
    bankAcc: "",
    bankIFSC: "",
    nomineeDetails: "",
    companyForm: false,
    registrationFees: '',
    isActive: false
  });

  const token = localStorage.getItem("token");

  const postEmployeeData = async(employeeData) => {
    if(!validateInput(employeeData)){return;}
    try {
      setLoading(true);
      const response = await axios.post(`${config.endpoint}employeeData/registerEmployee`, employeeData, {
        headers: { 'content-type': 'application/json', 'Authorization': `Bearer ${token}` },
      });
      setLoading(false);
      if (response.status === 201) {
        enqueueSnackbar("Employee Registered", { variant: "success" });
        history.push("/employeeRegistration");
      }
    } catch (e) {
      setLoading(false);
      if (e.response && e.response.status === 400) {
        enqueueSnackbar(e.response.data.message, { variant: "error" });
      } else {
        enqueueSnackbar("Something went wrong in the backend", { variant: "error" });
      }
    }
  };

  function employeeDetails(e) {
    const { name, value } = e.target;
    setEmployeeData({ ...employeeData, [name]: value });
  }

  const validateInput = (data) => {
    if (!data.phone) {
      enqueueSnackbar("Phone is a required field", { variant: "warning" });
      return false;
    }
    return true;
  };

  useEffect(() => {
    const selectedSite = siteData?.siteData?.find(x => x.siteId === employeeData.siteId);
    setEmployeeData(prevData => ({ ...prevData, siteName: selectedSite?.siteName || "" }));
  }, [employeeData.siteId, siteData.siteData]);

  return (
    <>
      <Header />
        <form>
          <div className="text-field-page">
            <h1>Employee Registration Form</h1>
            <div className="text-field-container">
              <div className="text-field">
                <span>Employee Name</span>
                <input
                  name="empName"
                  type="text"
                  placeholder="Employee Name"
                  value={employeeData.empName}
                  onChange={(e) => employeeDetails(e)}
                />
              </div>
              <div className="text-field">
                <span>Gender</span>
                <select
                  name="gender"
                  value={employeeData.gender}
                  onChange={(e) => employeeDetails(e)}
                >
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </select>
              </div>
              <div className="text-field">
                <span>Address</span>
                <input
                  name="address"
                  type="text"
                  placeholder="Address"
                  value={employeeData.address}
                  onChange={(e) => employeeDetails(e)}
                />
              </div>
              <div className="text-field">
                <span>Phone</span>
                <input
                  name="phone"
                  type="number"
                  placeholder="Phone No."
                  value={employeeData.phone}
                  onChange={(e) => employeeDetails(e)}
                />
              </div>
              <div className="text-field">
                <span>Birth Date</span>
                <input
                  name="birthDate"
                  type="date"
                  value={employeeData.birthDate.split("T")[0]}
                  onChange={(e) => employeeDetails(e)}
                />
              </div>
              <div className="text-field">
                <span>Designation</span>
                <input
                  name="designation"
                  type="text"
                  placeholder="Designation"
                  value={employeeData.designation}
                  onChange={(e) => employeeDetails(e)}
                />
              </div>
              <div className="text-field">
                <span>Salary</span>
                <input
                  name="salary"
                  type="number"
                  placeholder="Salary"
                  value={employeeData.salary}
                  onChange={(e) => employeeDetails(e)}
                />
              </div>
              <div className="text-field">
                <span>Joining Date</span>
                <input
                  name="joiningDate"
                  type="date"
                  value={employeeData.joiningDate.split("T")[0]}
                  onChange={(e) => employeeDetails(e)}
                />
              </div>
              <div className="text-field">
                <span>Select Working Site</span>
                <select
                  className="form-select"
                  name="siteId"
                  value={employeeData.siteId}
                  onChange={(e) => employeeDetails(e)}
                >
                  <option value="">Select an option</option>
                  {siteData?.siteData.map((x) => (
                    <option value={x.siteId} key={x.siteId}>
                      {x.siteName} - {x.siteId}
                    </option>
                  ))}
                </select>
              </div>
              <div className="text-field">
                <span>UAN</span>
                <input
                  name="uan"
                  type="text"
                  placeholder="UAN"
                  value={employeeData.uan}
                  onChange={(e) => employeeDetails(e)}
                />
              </div>
              <div className="text-field">
                <span>ESIC</span>
                <input
                  name="esic"
                  type="text"
                  placeholder="ESIC"
                  value={employeeData.esic}
                  onChange={(e) => employeeDetails(e)}
                />
              </div>
              <div className="text-field">
                <span>Aadhar No</span>
                <input
                  name="aadharNo"
                  type="text"
                  placeholder="Aadhar No"
                  value={employeeData.aadharNo}
                  onChange={(e) => employeeDetails(e)}
                />
              </div>
              <div className="text-field">
                <span>PAN No</span>
                <input
                  name="panNo"
                  type="text"
                  placeholder="PAN No"
                  value={employeeData.panNo}
                  onChange={(e) => employeeDetails(e)}
                />
              </div>
              <div className="text-field">
                <span>Photo</span>
                <input
                  name="photo"
                  type="url"
                  placeholder="Photo URL"
                  value={employeeData.photo}
                  onChange={(e) => employeeDetails(e)}
                />
              </div>
              <div className="text-field">
                <span>Bank Account</span>
                <input
                  name="bankAcc"
                  type="text"
                  placeholder="Bank Account"
                  value={employeeData.bankAcc}
                  onChange={(e) => employeeDetails(e)}
                />
              </div>
              <div className="text-field">
                <span>Bank IFSC</span>
                <input
                  name="bankIFSC"
                  type="text"
                  placeholder="Bank IFSC"
                  value={employeeData.bankIFSC}
                  onChange={(e) => employeeDetails(e)}
                />
              </div>
              <div className="text-field">
                <span>Nominee Details</span>
                <input
                  name="nomineeDetails"
                  type="text"
                  placeholder="Nominee Details"
                  value={employeeData.nomineeDetails}
                  onChange={(e) => employeeDetails(e)}
                />
              </div>
              <div className="text-field">
                <span>Company Form</span>
                <input
                  name="companyForm"
                  type="checkbox"
                  checked={employeeData.companyForm}
                  onChange={(e) =>
                    setEmployeeData({ ...employeeData, companyForm: e.target.checked })
                  }
                />
              </div>
              <div className="text-field">
                <span>Registration Fees</span>
                <input
                  name="registrationFees"
                  type="number"
                  placeholder="Registration Fees"
                  value={employeeData.registrationFees}
                  onChange={(e) => employeeDetails(e)}
                />
              </div>
              <div className="text-field">
                <span>Police Verification</span>
                <input
                  name="policeVer"
                  type="checkbox"
                  checked={employeeData.policeVer}
                  onChange={(e) =>
                    setEmployeeData({ ...employeeData, policeVer: e.target.checked })
                  }
                />
              </div>
              <div className="text-field">
                <span>Medical Verification</span>
                <input
                  name="medicalVer"
                  type="checkbox"
                  checked={employeeData.medicalVer}
                  onChange={(e) =>
                    setEmployeeData({ ...employeeData, medicalVer: e.target.checked })
                  }
                />
              </div>
              <div className="text-field">
                <span>ID Card</span>
                <input
                  name="iCard"
                  type="checkbox"
                  checked={employeeData.iCard}
                  onChange={(e) =>
                    setEmployeeData({ ...employeeData, iCard: e.target.checked })
                  }
                />
              </div>
              <div className="text-field">
                <span>Is Active</span>
                <input
                  name="isActive"
                  type="checkbox"
                  checked={employeeData.isActive}
                  onChange={(e) =>
                    setEmployeeData({ ...employeeData, isActive: e.target.checked })
                  }
                />
              </div>
            </div>
            {loadings ? (
              <Box display="flex" justifyContent="center" alignItems="center">
                <CircularProgress size={25} color="primary" />
              </Box>
            ) : (
              <Button className="button" variant="contained" onClick={() => postEmployeeData(employeeData)} style={{width:'15vw'}}>
                Register Employee
              </Button>
            )}
          </div>
        </form>
      <Footer />
    </>
  );
}

export default EmployeeDetails;