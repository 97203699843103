import { Button, Avatar, Stack } from '@mui/material';
import './Header.css'
import { Box } from "@mui/system";
import { useHistory } from "react-router-dom"
import { Link } from 'react-router-dom';

const Header = () => {

    const history = useHistory();

    const logout = () =>{
        localStorage.removeItem("email");
        localStorage.removeItem("token");
        localStorage.removeItem("name");
        history.push("/")
    };

    return(
       <div className="header">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-3">
                        <Link to="/home" className="logo-link">
                            <img src="company_Logo.png" alt="Logo" className="logo" />
                        </Link>
                    </div>
                    <div className="col-6" style={{paddingRight:'20%'}}>
                        <h3 className="company-name" style={{color:'white'}}>S S Security Services</h3>
                    </div>
                    <div className="col-2 text-end">
                        <button className="btn btn-danger logout-button" onClick={logout}>Logout</button>
                    </div>
                </div>
            </div>
        </div>      
    )
}
    
export default Header;