import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "../Header";
import Footer from "../Footer";
import axios from "axios";
import { config } from "../../App";
import { useSnackbar } from "notistack";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { Button, CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";

const EditSite = () => {

  const location = useLocation();
  const editSiteId = location.state;

  const { siteData } = useSelector((state) => state.siteData);
  const editSiteData = siteData.find((site) => site.siteId === editSiteId);

  const [formData, setFormData] = useState({
    siteName: editSiteData?.siteName || "",
    siteAddress: editSiteData?.siteAddress || "",
    totalEmployees: editSiteData?.totalEmployees || 0,
    estimatedCostPerMonth: editSiteData?.estimatedCostPerMonth || 0,
    compliance: editSiteData?.compliance || "",
    gstPercentage: editSiteData?.gstPercentage || 0,
    contactPerson: editSiteData?.contactPerson || "",
    contactPersonNo: editSiteData?.contactPersonNo || "",
    referral: editSiteData?.referral || "",
    isActive: editSiteData?.isActive || false,
  });

  const [progress, setProgress] = useState(false);
  const token = localStorage.getItem("token");
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setProgress(true);
      const response = await axios.put(
        `${config.endpoint}siteData/editSite/${editSiteId}`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setProgress(false);

      if (response.status === 200) {
        enqueueSnackbar("Site Updated Successfully", { variant: "success" });
        history.push("/site/manageSite");
      }
    } catch (e) {
      setProgress(false);
      if (e.response && e.response.status === 400) {
        enqueueSnackbar(e.response.data.message, { variant: "error" });
      } else {
        enqueueSnackbar("Something went wrong in the backend", {
          variant: "error",
        });
      }
    }
  };

  return (
    <>
      <Header />
      <div className="text-field-page">
        <h1>Edit Site</h1>
        <form onSubmit={handleSubmit}>
          <div className="text-field-container">
            <div className="text-field">
              <span>Site Name</span>
              <input
                type="text"
                name="siteName"
                className="form-control"
                required
                value={formData.siteName}
                onChange={handleChange}
              />
            </div>
            <div className="text-field">
              <span>Site Address</span>
              <input
                type="text"
                name="siteAddress"
                className="form-control"
                required
                value={formData.siteAddress}
                onChange={handleChange}
              />
            </div>
            <div className="text-field">
              <span>Total Employees</span>
              <input
                type="number"
                name="totalEmployees"
                className="form-control"
                required
                min="0"
                value={formData.totalEmployees}
                onChange={handleChange}
              />
            </div>
            <div className="text-field">
              <span>Estimated Cost Per Month</span>
              <input
                type="number"
                name="estimatedCostPerMonth"
                className="form-control"
                required
                min="0"
                value={formData.estimatedCostPerMonth}
                onChange={handleChange}
              />
            </div>
            <div className="text-field">
              <span>Compliance</span>
              <select
                name="compliance"
                className="form-control"
                required
                value={formData.compliance}
                onChange={handleChange}
              >
                <option value="">Select</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </div>
            <div className="text-field">
              <span>GST Percentage</span>
              <input
                type="number"
                name="gstPercentage"
                className="form-control"
                required
                min="0"
                max="100"
                value={formData.gstPercentage}
                onChange={handleChange}
              />
            </div>
            <div className="text-field">
              <span>Contact Person</span>
              <input
                type="text"
                name="contactPerson"
                className="form-control"
                value={formData.contactPerson}
                onChange={handleChange}
              />
            </div>
            <div className="text-field">
              <span>Contact Person No</span>
              <input
                type="text"
                name="contactPersonNo"
                className="form-control"
                pattern="\d{10}"
                title="Phone Number is not valid"
                value={formData.contactPersonNo}
                onChange={handleChange}
              />
            </div>
            <div className="text-field">
              <span>Referral</span>
              <input
                type="text"
                name="referral"
                className="form-control"
                value={formData.referral}
                onChange={handleChange}
              />
            </div>
            <div className="text-field">
              <span>Is Active</span>
              <input
                type="checkbox"
                name="isActive"
                checked={formData.isActive}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="mt-4">
            {progress ? (
              <Box display="flex" justifyContent="center" alignItems="center">
                <CircularProgress size={25} color="primary" />
              </Box>
            ) : (
              <Button
                type="submit"
                className="button"
                style={{ width: "15vw" }}
                variant="contained"
              >
                Update Site
              </Button>
            )}
          </div>
        </form>
      </div>
      <Footer />
    </>
  );
};

export default EditSite;
