import React, { useState } from "react";
import axios from "axios";
import { useHistory, Link } from "react-router-dom";
import { useSnackbar } from "notistack";
import { config } from "../App";
import { CircularProgress, Button, TextField, Stack } from "@mui/material";
import { Container, Row, Col, Image } from "react-bootstrap";
import "./Login.css";
import Footer from './Footer'

const Login = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({ email: "", password: "" });
  const history = useHistory();

  const login = async (formData) => {
    if (!validateInput(formData)) return;
    try {
      setLoading(true);
      const response = await axios.post(`${config.endpoint}/auth/login`, {
        email: formData.email,
        password: formData.password,
      });
      setLoading(false);
      if (response.status === 200) {
        enqueueSnackbar("Logged in", { variant: "success" });
        persistLogin(response.data.tokens.access.token, response.data.user.email, response.data.user.name, response.data.user.companyId);
        history.push("/home");
      }
    } catch (e) {
      setLoading(false);
      if (e.response && e.response.status === 401) {
        enqueueSnackbar(e.response.data.message, { variant: "error" });
      } else {
        enqueueSnackbar("Something went wrong in the backend", { variant: "error" });
      }
    }
  };

  const data = (name) => (e) => {
    setFormData({ ...formData, [name]: e.target.value });
  };

  const validateInput = (data) => {
    if (!data.email) {
      enqueueSnackbar("Email is a required field", { variant: "warning" });
      return false;
    }
    if (!data.password) {
      enqueueSnackbar("Password is a required field", { variant: "warning" });
      return false;
    }
    return true;
  };

  const persistLogin = (token, email, name, companyId) => {
    localStorage.setItem("token", token);
    localStorage.setItem("email", email);
    localStorage.setItem("name", name);
    localStorage.setItem("companyId", companyId);
  };

  return (
    <>
    <h2 style={{fontWeight:'bold', fontSize:'45px', color:'#005250'}}>S S Security Services</h2>
    <Container fluid className="login-container d-flex align-items-center justify-content-center min-vh-100">
      <Row className="w-100">
        <Col md={6} className="d-none d-md-block">
          <Image src="guard-image.png" alt="Guard" className="img-fluid" />
        </Col>
        <Col md={6} xs={12} className="d-flex flex-column align-items-center justify-content-center">
          <Stack spacing={2} className="form">
            <h2 className="title">Login</h2>
            <Image src="company_Logo.png" alt="Company Logo" className="mb-3" />
            <TextField
              id="email"
              label="Email"
              variant="outlined"
              title="Email"
              name="email"
              placeholder="Enter Email"
              value={formData.email}
              onChange={data("email")}
              fullWidth
            />
            <TextField
              id="password"
              variant="outlined"
              label="Password"
              name="password"
              type="password"
              fullWidth
              placeholder="Enter Password"
              value={formData.password}
              onChange={data("password")}
            />
            {loading ? (
              <div className="d-flex justify-content-center">
                <CircularProgress size={25} color="primary" />
              </div>
            ) : (
              <Button variant="contained" onClick={() => login(formData)}>
                Login
              </Button>
            )}
            <p className="secondary-action">
              Don't have an account? <Link to="/register">Register now</Link>
            </p>
          </Stack>
        </Col>
      </Row>
    </Container>
    <Footer />
    </>
  );
};

export default Login;
