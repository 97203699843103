import React from 'react'
import {useHistory} from "react-router-dom";
import Header from "../Header";
import Cards from '../../common/Cards';
import Footer from '../Footer';

function AttendanceHome() {

    const history = useHistory();

    const attendanceRegister = () => {
        history.push("/attendanceRegister")
    }
    const siteAttendance = () => {
        history.push("/siteAttendance")
    }
    const employeeAttendance = () => {
        history.push("/employeeAttendance")
    }

    return (
    <>
    <Header /><br></br>
    <div className="container-fluid">
        <div className="row">
            <Cards title="Daily Attendanace" text="This is an option to Create daily attendanace" name="View Details" page={attendanceRegister}/>
            <Cards title="View/Download Site Attendanace" text="View and download attendanace site wise" name="View Details" page={siteAttendance}/>
            <Cards title="View/Download Employee Attendanace" text="View and download attendanace employee wise" name="View Details" page={employeeAttendance}/>
        </div>
    </div>
    <Footer />
    </>
    )
}

export default AttendanceHome;
