// src/DressEntryForm.js
import React, { useState } from 'react';
import axios from 'axios';

const DressStock = () => {
  const [formData, setFormData] = useState({
    items: [
      { itemName: '', sizes: [{ size: '', quantity: '' }] },
      { itemName: '', sizes: [{ size: '', quantity: '' }] }
    ],
    billNo: '',
    billAmount: '',
    purchaserName: '',
    dateOfPurchase: ''
  });

  const handleChange = (e) => {
    const { name, value, dataset } = e.target;
    if (name.startsWith('itemName')) {
      const index = dataset.index;
      setFormData((prev) => {
        const newItems = [...prev.items];
        newItems[index].itemName = value;
        return { ...prev, items: newItems };
      });
    } else if (name.startsWith('size')) {
      const [index, sizeIndex] = dataset.index.split('-');
      setFormData((prev) => {
        const newItems = [...prev.items];
        newItems[index].sizes[sizeIndex].size = value;
        return { ...prev, items: newItems };
      });
    } else if (name.startsWith('quantity')) {
      const [index, sizeIndex] = dataset.index.split('-');
      setFormData((prev) => {
        const newItems = [...prev.items];
        newItems[index].sizes[sizeIndex].quantity = value;
        return { ...prev, items: newItems };
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put('/api/dress/12345', formData);
      console.log(response.data);
      alert('Entry saved successfully!');
    } catch (error) {
      console.error('Error saving entry:', error);
      alert('Error saving entry');
    }
  };

  return (
    <div className="container mt-5">
      <h2 className="mb-4">Dress Entry Form</h2>
      <form onSubmit={handleSubmit}>
        {formData.items.map((item, index) => (
          <div key={index} className="mb-3">
            <input
              type="text"
              className="form-control"
              name={`itemName-${index}`}
              data-index={index}
              placeholder="Item Name"
              value={item.itemName}
              onChange={handleChange}
              required
            />
            {item.sizes.map((size, sizeIndex) => (
              <div key={sizeIndex} className="d-flex mt-2">
                <input
                  type="text"
                  className="form-control me-2"
                  name={`size-${index}-${sizeIndex}`}
                  data-index={`${index}`}
                  placeholder="Size"
                  value={size.size}
                  onChange={handleChange}
                  required
                />
                <input
                  type="number"
                  className="form-control"
                  name={`quantity-${index}-${sizeIndex}`}
                  data-index={`${index}-${sizeIndex}`}
                  placeholder="Quantity"
                  value={size.quantity}
                  onChange={handleChange}
                  required
                />
              </div>
            ))}
          </div>
        ))}
        <div className="mb-3">
          <input
            type="text"
            className="form-control"
            name="billNo"
            placeholder="Bill No"
            value={formData.billNo}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <input
            type="number"
            className="form-control"
            name="billAmount"
            placeholder="Bill Amount"
            value={formData.billAmount}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <input
            type="text"
            className="form-control"
            name="purchaserName"
            placeholder="Purchaser Name"
            value={formData.purchaserName}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <input
            type="date"
            className="form-control"
            name="dateOfPurchase"
            value={formData.dateOfPurchase}
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit" className="btn btn-primary">Submit</button>
      </form>
    </div>
  );
};

export default DressStock;
