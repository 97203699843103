import React, { useState, useEffect } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { Button, CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { useSnackbar } from "notistack";
import { useHistory, useLocation } from "react-router-dom";
import { config } from "../../App";
import "./EmployeeDetails.css";
import { useSelector, useDispatch } from "react-redux";
import { updateEmployee } from '../../actions/index';

const EditEmployee = () => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const location = useLocation();
  const editEmployeeId = location.state;
  const { employeeData, siteData } = useSelector(state => state);

  let [loadings, setLoading] = useState(false);
  const dispatch = useDispatch();

  const editEmployeeData = employeeData.employeeData.find((emp) => emp.empId === editEmployeeId);

  let [formData, setFormData] = useState({
    siteId: editEmployeeData.siteId,
    empName: editEmployeeData.empName,
    gender: editEmployeeData.gender,
    phone: editEmployeeData.phone,
    address: editEmployeeData.address,
    birthDate: editEmployeeData.birthDate,
    joiningDate: editEmployeeData.joiningDate,
    designation: editEmployeeData.designation,
    salary: editEmployeeData.salary,
    registrationDate: editEmployeeData.registrationDate,
    uan: editEmployeeData.uan,
    esic: editEmployeeData.esic,
    policeVer: editEmployeeData.policeVer,
    medicalVer: editEmployeeData.medicalVer,
    iCard: editEmployeeData.iCard,
    aadharNo: editEmployeeData.aadharNo,
    panNo: editEmployeeData.panNo,
    photo: editEmployeeData.photo,
    bankAcc: editEmployeeData.bankAcc,
    bankIFSC: editEmployeeData.bankIFSC,
    nomineeDetails: editEmployeeData.nomineeDetails,
    companyForm: editEmployeeData.companyForm,
    registrationFees: editEmployeeData.registrationFees,
    isActive: editEmployeeData.isActive,
  });

  const token = localStorage.getItem("token");

  const updateEmployeeData = async (formData) => {
    if (!validateInput(formData)) {
      return;
    }
    formData.siteName = siteData.siteData.filter(site=>site.siteId===formData.siteId)[0].siteName
    try {
      setLoading(true);
      const response = await axios.put(
        `${config.endpoint}employeeData/editEmployee/${editEmployeeId}`,
        formData,
        {
            headers: { 'content-type': 'application/json', 'Authorization': `Bearer ${token}` },
        }
      );
      setLoading(false);
      if (response.status === 200) {
        dispatch(updateEmployee({ ...formData, empId: editEmployeeId }));
        enqueueSnackbar("Employee Updated Successfully", { variant: "success" });
        history.push("/employee/manageEmployee");
      }
    } catch (e) {
      setLoading(false);
      if (e.response && e.response.status === 400) {
        enqueueSnackbar(e.response.data.message, { variant: "error" });
      } else {
        enqueueSnackbar("Something went wrong in the backend", { variant: "error" });
      }
    }
  };

  function employeeDetails(e) {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  }

  const validateInput = (data) => {
    if (!data.phone) {
      enqueueSnackbar("Phone is a required field", { variant: "warning" });
      return false;
    }
    return true;
  };

  useEffect(() => {
    const selectedSite = siteData?.siteData?.find(x => x.siteId === editEmployeeData.siteId);
    setFormData(prevData => ({ ...prevData, siteName: selectedSite?.siteName || "" }));
  }, [editEmployeeData.siteId, siteData.siteData]);

  return (
    <>
      <Header />
        <form>
          <div className="text-field-page">
            <h1>Edit Employee Details</h1>
            <div className="text-field-container">
                <form>
                    <div className="text-field-page">
                        <div className="text-field-container">
                        <div className="text-field">
                            <span>Employee Name</span>
                            <input
                            name="empName"
                            type="text"
                            placeholder="Employee Name"
                            value={formData.empName}
                            onChange={(e) => employeeDetails(e)}
                            />
                        </div>
                        <div className="text-field">
                            <span>Gender</span>
                            <select
                            name="gender"
                            value={formData.gender}
                            onChange={(e) => employeeDetails(e)}
                            >
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                            </select>
                        </div>
                        <div className="text-field">
                            <span>Address</span>
                            <input
                            name="address"
                            type="text"
                            placeholder="Address"
                            value={formData.address}
                            onChange={(e) => employeeDetails(e)}
                            />
                        </div>
                        <div className="text-field">
                            <span>Phone</span>
                            <input
                            name="phone"
                            type="number"
                            placeholder="Phone No."
                            value={formData.phone}
                            onChange={(e) => employeeDetails(e)}
                            />
                        </div>
                        <div className="text-field">
                            <span>Birth Date</span>
                            <input
                            name="birthDate"
                            type="date"
                            value={formData?.birthDate?.split("T")[0]}
                            onChange={(e) => employeeDetails(e)}
                            />
                        </div>
                        <div className="text-field">
                            <span>Designation</span>
                            <input
                            name="designation"
                            type="text"
                            placeholder="Designation"
                            value={formData.designation}
                            onChange={(e) => employeeDetails(e)}
                            />
                        </div>
                        <div className="text-field">
                            <span>Salary</span>
                            <input
                            name="salary"
                            type="number"
                            placeholder="Salary"
                            value={formData.salary}
                            onChange={(e) => employeeDetails(e)}
                            />
                        </div>
                        <div className="text-field">
                            <span>Joining Date</span>
                            <input
                            name="joiningDate"
                            type="date"
                            value={formData?.joiningDate?.split("T")[0]}
                            onChange={(e) => employeeDetails(e)}
                            />
                        </div>
                        <div className="text-field">
                            <span>Select Working Site</span>
                            <select
                            className="form-select"
                            name="siteId"
                            value={formData.siteId}
                            onChange={(e) => employeeDetails(e)}
                            >
                            <option value="">Select an option</option>
                            {siteData?.siteData.map((x) => (
                                <option value={x.siteId} key={x.siteId}>
                                {x.siteName} - {x.siteId}
                                </option>
                            ))}
                            </select>
                        </div>
                        <div className="text-field">
                            <span>UAN</span>
                            <input
                            name="uan"
                            type="text"
                            placeholder="UAN"
                            value={formData.uan}
                            onChange={(e) => employeeDetails(e)}
                            />
                        </div>
                        <div className="text-field">
                            <span>ESIC</span>
                            <input
                            name="esic"
                            type="text"
                            placeholder="ESIC"
                            value={formData.esic}
                            onChange={(e) => employeeDetails(e)}
                            />
                        </div>
                        <div className="text-field">
                            <span>Aadhar No</span>
                            <input
                            name="aadharNo"
                            type="text"
                            placeholder="Aadhar No"
                            value={formData.aadharNo}
                            onChange={(e) => employeeDetails(e)}
                            />
                        </div>
                        <div className="text-field">
                            <span>PAN No</span>
                            <input
                            name="panNo"
                            type="text"
                            placeholder="PAN No"
                            value={formData.panNo}
                            onChange={(e) => employeeDetails(e)}
                            />
                        </div>
                        <div className="text-field">
                            <span>Photo</span>
                            <input
                            name="photo"
                            type="url"
                            placeholder="Photo URL"
                            value={formData.photo}
                            onChange={(e) => employeeDetails(e)}
                            />
                        </div>
                        <div className="text-field">
                            <span>Bank Account</span>
                            <input
                            name="bankAcc"
                            type="text"
                            placeholder="Bank Account"
                            value={formData.bankAcc}
                            onChange={(e) => employeeDetails(e)}
                            />
                        </div>
                        <div className="text-field">
                            <span>Bank IFSC</span>
                            <input
                            name="bankIFSC"
                            type="text"
                            placeholder="Bank IFSC"
                            value={formData.bankIFSC}
                            onChange={(e) => employeeDetails(e)}
                            />
                        </div>
                        <div className="text-field">
                            <span>Nominee Details</span>
                            <input
                            name="nomineeDetails"
                            type="text"
                            placeholder="Nominee Details"
                            value={formData.nomineeDetails}
                            onChange={(e) => employeeDetails(e)}
                            />
                        </div>
                        <div className="text-field">
                            <span>Company Form</span>
                            <input
                            name="companyForm"
                            type="checkbox"
                            checked={formData.companyForm}
                            onChange={(e) =>
                                setFormData({ ...formData, companyForm: e.target.checked })
                            }
                            />
                        </div>
                        <div className="text-field">
                            <span>Registration Fees</span>
                            <input
                            name="registrationFees"
                            type="number"
                            placeholder="Registration Fees"
                            value={formData.registrationFees}
                            onChange={(e) => employeeDetails(e)}
                            />
                        </div>
                        <div className="text-field">
                            <span>Police Verification</span>
                            <input
                            name="policeVer"
                            type="checkbox"
                            checked={formData.policeVer}
                            onChange={(e) =>
                                setFormData({ ...formData, policeVer: e.target.checked })
                            }
                            />
                        </div>
                        <div className="text-field">
                            <span>Medical Verification</span>
                            <input
                            name="medicalVer"
                            type="checkbox"
                            checked={formData.medicalVer}
                            onChange={(e) =>
                                setFormData({ ...formData, medicalVer: e.target.checked })
                            }
                            />
                        </div>
                        <div className="text-field">
                            <span>ID Card</span>
                            <input
                            name="iCard"
                            type="checkbox"
                            checked={formData.iCard}
                            onChange={(e) =>
                                setFormData({ ...formData, iCard: e.target.checked })
                            }
                            />
                        </div>
                        <div className="text-field">
                            <span>Is Active</span>
                            <input
                            name="isActive"
                            type="checkbox"
                            checked={formData.isActive}
                            onChange={(e) =>
                                setFormData({ ...formData, isActive: e.target.checked })
                            }
                            />
                        </div>
                        </div>
                    </div>
                </form>
            </div>
            {loadings ? (
              <Box display="flex" justifyContent="center" alignItems="center">
                <CircularProgress size={25} color="primary" />
              </Box>
            ) : (
              <Button
                className="button"
                variant="contained"
                onClick={() => updateEmployeeData(formData)}
                style={{ width: '15vw' }}
              >
                Update Employee
              </Button>
            )}
          </div>
        </form>
      <Footer />
    </>
  );
}

export default EditEmployee;
