import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSnackbar } from "notistack";
import { useSelector, useDispatch } from "react-redux";
import { Container, Row, Col, Form, Button, Modal } from "react-bootstrap";
import { FaEdit, FaTrash } from "react-icons/fa";
import Header from "../Header";
import * as XLSX from "xlsx";
import "./Attendance.css";
import Footer from '../Footer';
import { config } from "../../App";
import { deleteAttendance, fetchEmployeeData, fetchSiteData } from '../../actions/index';

function SiteAttendance() {
  const { enqueueSnackbar } = useSnackbar();
  const [attendanceTable, setAttendanceTable] = useState([]);
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();

  const { siteData, employeeData } = useSelector((state) => state);
  const [headerValues, setHeaderValues] = useState({ siteId: "", month: "", year: "" });
  const [selectedCell, setSelectedCell] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [editDayReport, setEditDayReport] = useState("");

  useEffect(()=>{
    dispatch(fetchEmployeeData());
    dispatch(fetchSiteData());
  },[])

  const months = [
    { value: "01", label: "January" },
    { value: "02", label: "February" },
    { value: "03", label: "March" },
    { value: "04", label: "April" },
    { value: "05", label: "May" },
    { value: "06", label: "June" },
    { value: "07", label: "July" },
    { value: "08", label: "August" },
    { value: "09", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
  ];

  const years = [
    { value: "2024", label: "2024" },
    { value: "2025", label: "2025" },
  ];

  const daysInMonth = () => new Date(+headerValues.year, +headerValues.month, 0).getDate();
  const daysArray = Array.from({ length: daysInMonth() }, (_, i) => i + 1);

  function handleHeaderInput(e) {
    const { name, value } = e.target;
    setHeaderValues({ ...headerValues, [name]: value });
  }

  useEffect(() => {
    const fetchAttendance = async () => {
      if (headerValues.siteId && headerValues.month && headerValues.year) {
        try {
          const response = await axios.get(`${config.endpoint}attendance/getAttendance`,{
              params: {
                siteId: headerValues.siteId,
                month: `${headerValues.year}-${headerValues.month}`,
              },
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          const data = response.data;
          setAttendanceTable(data);
        } catch (error) {
          enqueueSnackbar("Failed to fetch attendance data", { variant: "error" });
        }
      }
    };
    fetchAttendance();
  }, [headerValues]);

  const handleDelete = async (item, dayIndex) => {
    const attendanceDate = `${headerValues.year}-${headerValues.month}-${String(dayIndex).padStart(2, "0")}`;
    try {
      await axios.delete(`${config.endpoint}attendance/`, {
        data: {
          empId: item.empId,
          siteId: headerValues.siteId,
          attendanceDate,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      enqueueSnackbar("Attendance record deleted successfully", { variant: "success" });
      setShowPopup(false)
      setHeaderValues({ ...headerValues });
    } catch (error) {
      enqueueSnackbar("Failed to delete attendance record", { variant: "error" });
    }
  };

  const handleEdit = async () => {
    if (!selectedCell || !editDayReport) return;

    const { empId, day } = selectedCell;
    const attendanceDate = `${headerValues.year}-${headerValues.month}-${String(day).padStart(2, "0")}`;
    const payload = [
      {
        siteId: headerValues.siteId,
        attendanceDate,
        empId,
        dayReport: editDayReport,
      },
    ];

    try {
      await axios.put(`${config.endpoint}attendance/dailyAttendance`, payload, {
          headers: {Authorization: `Bearer ${token}`},
        });

      enqueueSnackbar("Attendance record updated successfully", { variant: "success" });
      setShowPopup(false);
      setEditDayReport(""); 

      // Refresh the attendance table after updating
      setHeaderValues({ ...headerValues });  // Triggers useEffect to refetch data
    } catch (error) {
      enqueueSnackbar("Failed to update attendance record", { variant: "error" });
    }
  };

  const handleCellClick = (item, day, dayReport) => {
    setSelectedCell({ empId: item.empId, dayReport, day });
    setShowPopup(true);
  };

  const downloadExcel = (siteName, siteAddress, attendanceMonth) => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet([
      ["Name:", siteName],
      ["Place:", siteAddress],
      ["Month:", attendanceMonth],
      [],
      ["Employee Name", "Total", ...daysArray.map(day => day.toString())]
    ]);

    const data = attendanceTable.map((item) => {
      const total = item.totalAttendance;
      return [
        item.empName,
        total,
        ...daysArray.map((day) => item.attendance.find(att => new Date(att.attendanceDate).getDate() === day)?.dayReport || ""),
      ];
    });

    XLSX.utils.sheet_add_aoa(ws, data, { origin: -1 });

    // Apply styles (as in your original code)

    XLSX.utils.book_append_sheet(wb, ws, "Attendance");
    XLSX.writeFile(wb, `Attendance_${attendanceMonth}.xlsx`);
  };

  return (
    <>
      <Header />
      <Container fluid>
        <Row className="mt-3">
          <Col xs={12} md={4}>
            <h3 className="mb-3">Select Site</h3>
            <Form.Select name="siteId" value={headerValues.siteId} onChange={handleHeaderInput}>
              <option value="">Select an option</option>
              {siteData?.siteData.map((x) => (
                <option key={x.siteId} value={x.siteId}>
                  {x.siteName} - {x.siteId}
                </option>
              ))}
            </Form.Select>
          </Col>
          <Col xs={12} md={4}>
            <h3 className="mb-3">Month</h3>
            <Form.Select name="month" value={headerValues.month} onChange={handleHeaderInput}>
              <option value="">Select Month</option>
              {months.map((month) => (
                <option key={month.value} value={month.value}>
                  {month.label}
                </option>
              ))}
            </Form.Select>
          </Col>
          <Col xs={12} md={4}>
            <h3 className="mb-3">Year</h3>
            <Form.Select name="year" value={headerValues.year} onChange={handleHeaderInput}>
              <option value="">Select Year</option>
              {years.map((year) => (
                <option key={year.value} value={year.value}>
                  {year.label}
                </option>
              ))}
            </Form.Select>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col xs={12}>
            <div className="d-flex justify-content-end">
              <Button variant="primary" onClick={() => downloadExcel('siteName', 'siteAddress', 'attendanceMonth')}>
                Download Attendance Sheet
              </Button>
            </div>
            <div className="d-flex justify-content-end" style={{fontWeight: 'bold'}}>
              {'Total -  '}
              {attendanceTable.reduce((total, item) => total + item.totalAttendance, 0)}
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <div className="table-responsive">
              <table className="table table-bordered text-center">
                <thead>
                  <tr>
                    <th>S.No.</th>
                    <th>Employee ID</th>
                    <th>Employee Name</th>
                    {daysArray.map((day) => (
                      <th key={day}>{day}</th>
                    ))}
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {attendanceTable.map((item, index) => {
                    const employee = employeeData.employeeData.find((emp) => emp.empId === item.empId);
                    const employeeId = employee ? employee.empId : "Unknown";
                    const employeeName = employee ? employee.empName : "Unknown";

                    const attendanceLookup = {};
                    item.attendance.forEach((att) => {
                      const formattedDate = new Date(att.attendanceDate).getDate();
                      attendanceLookup[formattedDate] = att.dayReport;
                    });

                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{employeeId}</td>
                        <td>{employeeName}</td>
                        {daysArray.map((day, dayIndex) => (
                          <td
                            key={dayIndex}
                            onClick={() => handleCellClick(item, day, attendanceLookup[day])}
                            className="attendance-cell"
                            style={{
                              cursor: "pointer",
                              backgroundColor:
                                attendanceLookup[day] === "P"
                                  ? "green"
                                  : attendanceLookup[day] === "A"
                                  ? "red"
                                  : attendanceLookup[day] === "H"
                                  ? "yellow"
                                  : attendanceLookup[day] === "PL"
                                  ? "orange"
                                  : attendanceLookup[day] === "D"
                                  ? "purple"
                                  : "",
                            }}
                          >
                            {attendanceLookup[day] || ""}
                          </td>
                        ))}
                        <td>{item.totalAttendance}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
      </Container>

      {/* Popup for Edit/Delete */}
      <Modal show={showPopup} onHide={() => setShowPopup(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Manage Attendance</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>Select Attendance Status</Form.Label>
            <Form.Select
              value={editDayReport}
              onChange={(e) => setEditDayReport(e.target.value)}
            >
              <option value="">Select Status</option>
              <option value="P">Present</option>
              <option value="A">Absent</option>
              <option value="H">Half Day</option>
              <option value="PL">Paid Leave</option>
              <option value="D">Double</option>
            </Form.Select>
          </Form.Group>
          <div className="d-flex justify-content-between">
            <Button variant="danger" onClick={() => handleDelete(selectedCell, selectedCell.day)}>
              <FaTrash className="me-2" /> Delete
            </Button>
            <Button variant="success" onClick={handleEdit}>
              <FaEdit className="me-2" /> Edit
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Footer/>
    </>
  );
}

export default SiteAttendance;
