import React from 'react'
import {useHistory} from "react-router-dom";
import Header from "../Header";
import Cards from '../../common/Cards';
import Footer from '../Footer';

function SiteHome() {

    const history = useHistory();
    
    const addSiteDetail = () => {
        history.push("/site/addSiteDetail")
    }
    const manageSite = () => {
        history.push("/site/manageSite")
    }
    return (
    <>
    <Header /><br></br>
    <div className="container-fluid">
        <div className="row">
            <Cards title="Register New Site" text="This is an option to add Site data" name="View Details" page={addSiteDetail}/>
            <Cards title="View/Manage Site" text="This is an option to View/Edit Site data" name="View Details" page={manageSite}/>
        </div>
    </div>
    <Footer />
    </>
    )
}

export default SiteHome
