import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { Button, TextField, MenuItem, Select, Pagination, CircularProgress } from "@mui/material";
import { Container, Row, Col, Form, Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { config } from "../../App";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Box } from "@mui/system";
import Header from "../Header";
import Footer from "../Footer";
import "../EmployeePage/EmployeeDetails.css";
import { fetchEmployeeData, fetchSiteData } from '../../actions/index';

const EmployeeSalary = () => {
    const [salary, setSalary] = useState([]);
    const [filteredEmployees, setFilteredEmployees] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const history = useHistory();
    const dispatch = useDispatch();
    const token = localStorage.getItem("token");
    const [headerValues, setHeaderValues] = useState({ siteId: "", month: "", year: "" });

    const { enqueueSnackbar } = useSnackbar();

    useEffect(()=>{
        dispatch(fetchEmployeeData());
        dispatch(fetchSiteData());
    },[])

    const months = [
        { value: "01", label: "January" },
        { value: "02", label: "February" },
        { value: "03", label: "March" },
        { value: "04", label: "April" },
        { value: "05", label: "May" },
        { value: "06", label: "June" },
        { value: "07", label: "July" },
        { value: "08", label: "August" },
        { value: "09", label: "September" },
        { value: "10", label: "October" },
        { value: "11", label: "November" },
        { value: "12", label: "December" },
    ];

    const years = [
        { value: "2024", label: "2024" },
        { value: "2025", label: "2025" },
    ];

    const daysInMonth = () => new Date(+headerValues.year, +headerValues.month, 0).getDate();
    const daysArray = Array.from({ length: daysInMonth() }, (_, i) => i + 1);
    
    function handleHeaderInput(e) {
        const { name, value } = e.target;
        setHeaderValues({ ...headerValues, [name]: value });
    }

    const { loading, employeeData, siteData } = useSelector((state) => state);
    
    useEffect(() => {
        const fetchAttendance = async () => {
            if (headerValues.siteId && headerValues.month && headerValues.year) {
              try {
                await axios.get(`${config.endpoint}attendance/getAttendance`,{
                    params: {
                      siteId: headerValues.siteId,
                      month: `${headerValues.year}-${headerValues.month}`,
                    },
                    headers: {Authorization: `Bearer ${token}`,},
                }).then(response => {
                    let salaryDetails = response.data.map(res => {
                        let employeeSalary = employeeData.employeeData.find(emp=>emp.empId===res.empId).salary;
                        let earnings = Math.floor((employeeSalary / daysArray.length)) * res.totalAttendance;
                        return {
                            siteId: res.siteId,
                            empId: res.empId,
                            totalAttendance: res.totalAttendance,
                            wages: employeeSalary,
                            salary: earnings,
                            settled: res.settle
                        }
                    })
                    setSalary(salaryDetails)
                })
              } catch (error) {
                enqueueSnackbar("Failed to fetch attendance data"+error, { variant: "error" });
              }
            }
        };
        fetchAttendance();
    }, [headerValues]);

    const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    const filtered = salary.filter((employee) => {
        return (
            employee.empName.toLowerCase().includes(query) ||
            employee.siteName.includes(query)
        );
    });
        setFilteredEmployees(filtered);
        setCurrentPage(1);
    };

    const indexOfLastEmployee = currentPage * rowsPerPage;
    const indexOfFirstEmployee = indexOfLastEmployee - rowsPerPage;
    const currentEmployeesSalary = filteredEmployees.slice(indexOfFirstEmployee, indexOfLastEmployee);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(1);
    };
    
  return(
    <>
    <Header />
    <Container fluid>
        <Row className="mt-3">
            <Col xs={12} md={4}>
            <h3 className="mb-3">Select Site</h3>
            <Form.Select name="siteId" value={headerValues.siteId} onChange={handleHeaderInput}>
                <option value="">Select an option</option>
                {siteData?.siteData.map((x) => (
                <option key={x.siteId} value={x.siteId}>
                    {x.siteName} - {x.siteId}
                </option>
                ))}
            </Form.Select>
            </Col>
            <Col xs={12} md={4}>
            <h3 className="mb-3">Month</h3>
            <Form.Select name="month" value={headerValues.month} onChange={handleHeaderInput}>
                <option value="">Select Month</option>
                {months.map((month) => (
                <option key={month.value} value={month.value}>
                    {month.label}
                </option>
                ))}
            </Form.Select>
            </Col>
            <Col xs={12} md={4}>
            <h3 className="mb-3">Year</h3>
            <Form.Select name="year" value={headerValues.year} onChange={handleHeaderInput}>
                <option value="">Select Year</option>
                {years.map((year) => (
                <option key={year.value} value={year.value}>
                    {year.label}
                </option>
                ))}
            </Form.Select>
            </Col>
        </Row>
        <div className="text-field-page">
            <div className="search-header">
            <h1>Salary Details</h1>
            <div style={{marginLeft:'50%', fontWeight:'bold'}}>
                {'Total Salary -  '}
                {salary.reduce((total, item) => total + item.salary, 0)}
            </div>
            <TextField
                id="search"
                label="Search"
                variant="outlined"
                size="small"
                value={searchQuery}
                onChange={handleSearch}
                placeholder="Search by Employee Name, Site Name"
                style={{ marginLeft: "auto", marginRight: "10px" }}
            />
            </div>
            {loading ? (
            <Box display="flex" justifyContent="center" alignItems="center">
                <CircularProgress size={25} color="primary" />
            </Box>
            ) : (
            <table className="table table-striped">
            <thead>
                <tr>
                    <th>S No.</th>
                    <th>Employee Id</th>
                    <th>Employee Name</th>
                    <th>Total Attendance</th>
                    <th>Wages</th>
                    <th>Month Salary</th>
                    <th>Settle Status</th>
                    <th>Settle Date</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                {salary?.map((salary, index) => (
                <tr key={index+1}>
                    <td>{index+1}</td>
                    <td>{salary.empId}</td>
                    <td>{employeeData.employeeData.find((emp) => emp.empId === salary?.empId)?.empName}</td>
                    <td>{salary?.totalAttendance}</td>
                    <td>{salary?.wages}</td>
                    <td>{salary?.salary}</td>
                    <td>{salary?.settled}</td>
                    <td>''</td>
                    <td>
                        <Button variant="contained" color="primary" onClick={'() => handleEdit(employee.empId)'}>
                            Save
                        </Button>
                    </td>
                </tr>
                ))}
            </tbody>
            </table>
            )}
            <div className="pagination-container">
            <div className="rows-per-page">
                <span>Rows per page: </span>
                <Select
                value={rowsPerPage}
                onChange={handleRowsPerPageChange}
                style={{ marginLeft: "10px" }}
                >
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                </Select>
            </div>
            <Pagination
                count={Math.ceil(filteredEmployees.length / rowsPerPage)}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
                style={{ marginLeft: "auto" }}
            />
            </div>
        </div>
    </Container>
    <Footer />
    </>
  )
}

export default EmployeeSalary;