import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSnackbar } from "notistack";
import { config } from "../../App";
import { useSelector, useDispatch } from "react-redux";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Header from "../Header";
import Footer from "../Footer";
import * as XLSX from "xlsx";
import "./Attendance.css";
import { deleteAttendance, fetchEmployeeData, fetchSiteData } from '../../actions/index';

function EmployeeAttendance() {
  const { enqueueSnackbar } = useSnackbar();
  const [attendanceTable, setAttendanceTable] = useState([]);
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();

  useEffect(()=>{
    dispatch(fetchEmployeeData());
    dispatch(fetchSiteData());
  },[])

  const { employeeData, attendanceData, siteData } = useSelector((state) => state);
  const [headerValues, setHeaderValues] = useState({ empId: "", month: "", year: "" });

  const months = [
    { value: "01", label: "January" },
    { value: "02", label: "February" },
    { value: "03", label: "March" },
    { value: "04", label: "April" },
    { value: "05", label: "May" },
    { value: "06", label: "June" },
    { value: "07", label: "July" },
    { value: "08", label: "August" },
    { value: "09", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
  ];

  const years = [
    { value: "2024", label: "2024" },
    { value: "2025", label: "2025" },
  ];

  const daysInMonth = () => new Date(+headerValues.year, +headerValues.month, 0).getDate();
  const daysArray = Array.from({ length: daysInMonth() }, (_, i) => i + 1);

  function handleHeaderInput(e) {
    const { name, value } = e.target;
    setHeaderValues({ ...headerValues, [name]: value });
  }

  useEffect(() => {
    const fetchAttendance = async () => {
      if (headerValues.empId && headerValues.month && headerValues.year) {
        try {
          const response = await axios.get(`${config.endpoint}attendance/getAttendance`,{
              params: {
                empId: headerValues.empId,
                month: `${headerValues.year}-${headerValues.month}`,
              },
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          const data = response.data;
          setAttendanceTable(data);
        } catch (error) {
          enqueueSnackbar("Failed to fetch attendance data", { variant: "error" });
        }
      }
    };
    fetchAttendance();
  }, [headerValues, attendanceData, employeeData]);

  const handleDelete = async (deleteData, index) => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete attendance of ${employeeData.employeeData.find((emp) => emp.empId === deleteData.empId).empName} for ${`${headerValues.year}-${headerValues.month}-${index + 1}`}?`
    );
    if (confirmDelete) {
      try {
        const response = await axios.delete(`${config.endpoint}attendance/`, {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          data: {
            empId: deleteData.empId,
            attendanceDate: `${headerValues.year}-${headerValues.month}-${index + 1 < 10 ? "0" + (index + 1) : index + 1}`,
            siteId: deleteData.siteId
          },
        });

        if (response.status === 204) {
          enqueueSnackbar("Attendance Deleted", { variant: "success" });
          dispatch(deleteAttendance(deleteData.empId, `${headerValues.year}-${headerValues.month}-${index + 1 < 10 ? "0" + (index + 1) : index + 1}`, deleteData.siteId));
        }
      } catch (e) {
        if (e.response && e.response.status === 400) {
          enqueueSnackbar(e.response.data.message, { variant: "error" });
        } else {
          enqueueSnackbar("Something went wrong in the backend", { variant: "error" });
        }
      }
    }
  };

  const downloadExcel = (empName, attendanceMonth) => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet([
      ["Employee Name:", empName],
      ["Month:", attendanceMonth],
      [],
      ["Total", ...daysArray.map(day => day.toString())]
    ]);

    const data = attendanceTable.map((item) => {
      const total = item.totalDayReport.present +
                    item.totalDayReport.halfDay * 0.5 +
                    item.totalDayReport.paidLeave +
                    item.totalDayReport.double * 2;
      return [
        total,
        ...daysArray.map((day) => item.attendance[day] || ""),
      ];
    });

    XLSX.utils.sheet_add_aoa(ws, data, { origin: -1 });

    const applyCellStyle = (cell, bgColor) => {
      cell.s = {
        fill: {
          fgColor: { rgb: bgColor },
        },
        border: {
          top: { style: "thin", color: { rgb: "000000" } },
          bottom: { style: "thin", color: { rgb: "000000" } },
          left: { style: "thin", color: { rgb: "000000" } },
          right: { style: "thin", color: { rgb: "000000" } },
        },
      };
    };

    data.forEach((row, rowIndex) => {
      daysArray.forEach((day, colIndex) => {
        const cellRef = XLSX.utils.encode_cell({ r: rowIndex + 3, c: colIndex + 1 });
        const cell = ws[cellRef];
        if (cell) {
          let bgColor = "FFFFFF";
          switch (cell.v) {
            case "P":
              bgColor = "90EE90";
              break;
            case "A":
              bgColor = "FF6347";
              break;
            case "H":
              bgColor = "FFFF99";
              break;
            case "PL":
              bgColor = "ADD8E6";
              break;
            case "D":
              bgColor = "D8BFD8";
              break;
            default:
              break;
          }
          applyCellStyle(cell, bgColor);
        }
      });
    });

    const headerCells = ["A4", ...daysArray.map((_, i) => XLSX.utils.encode_cell({ r: 3, c: i + 1 }))];
    headerCells.forEach(cellRef => {
      const cell = ws[cellRef];
      if (cell) {
        applyCellStyle(cell, "CCCCCC");
      }
    });

    const totalRow = ["Total", data.reduce((sum, row) => sum + row[0], 0), ...Array(daysArray.length).fill("")];
    XLSX.utils.sheet_add_aoa(ws, [totalRow], { origin: -1 });

    ws["!cols"] = [
      { wpx: 100 },
      ...daysArray.map((_, colIndex) => ({
        wpx: Math.max(40, ...data.map(row => (row[colIndex + 1] || "").toString().length * 10))
      }))
    ];

    XLSX.utils.book_append_sheet(wb, ws, "Attendance");
    XLSX.writeFile(wb, `Attendance_${attendanceMonth}.xlsx`);
  };

  return (
    <>
      <Header />
      <Container fluid>
        <Row className="mt-3">
          <Col xs={12} md={4}>
            <h3 className="mb-3">Select Employee</h3>
            <Form.Select name="empId" value={headerValues.empId} onChange={handleHeaderInput}>
              <option value="">Select an option</option>
              {employeeData?.employeeData.map((x) => (
                <option key={x.empId} value={x.empId}>
                  {x.empName + ' - ' + x.empId}
                </option>
              ))}
            </Form.Select>
          </Col>
          <Col xs={12} md={4}>
            <h3 className="mb-3">Month</h3>
            <Form.Select name="month" value={headerValues.month} onChange={handleHeaderInput}>
              <option value="">Select Month</option>
              {months.map((month) => (
                <option key={month.value} value={month.value}>
                  {month.label}
                </option>
              ))}
            </Form.Select>
          </Col>
          <Col xs={12} md={4}>
            <h3 className="mb-3">Year</h3>
            <Form.Select name="year" value={headerValues.year} onChange={handleHeaderInput}>
              <option value="">Select Year</option>
              {years.map((year) => (
                <option key={year.value} value={year.value}>
                  {year.label}
                </option>
              ))}
            </Form.Select>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col xs={12}>
            <div className="d-flex justify-content-end">
              <Button variant="primary" onClick={()=>downloadExcel('empName', 'attendanceMonth')}>
                Download Attendance Sheet
              </Button>
            </div>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col xs={12}>
            <div className="table-responsive">
              <table className="table table-bordered text-center">
                <thead>
                  <tr>
                    <th>S No.</th>
                    <th>Site ID</th>
                    <th>Site Name</th>
                    {daysArray.map((day) => (
                      <th key={day}>{day}</th>
                    ))}
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {attendanceTable.map((item, index) => {
                    const attendanceLookup = {};
                    item?.attendance.forEach((att) => {
                      const formattedDate = new Date(att.attendanceDate).getDate();
                      attendanceLookup[formattedDate] = att?.dayReport;
                    });
                    return (
                      <tr key={index}>
                        <td>{index+1}</td>
                        <td>{item?.siteId}</td>
                        <td>{siteData.siteData.find((site) => site.siteId === item?.siteId)?.siteName}</td>
                        {daysArray.map((day, dayIndex) => (
                          <td
                            key={dayIndex}
                            onClick={() => handleDelete(item, dayIndex)}
                            className="attendance-cell"
                            style={{
                              cursor: "pointer",
                              backgroundColor:
                                attendanceLookup[day] === "P"
                                  ? "green"
                                  : attendanceLookup[day] === "A"
                                  ? "red"
                                  : attendanceLookup[day] === "H"
                                  ? "yellow"
                                  : attendanceLookup[day] === "PL"
                                  ? "orange"
                                  : attendanceLookup[day] === "D"
                                  ? "purple"
                                  : "",
                            }}
                          >
                            {attendanceLookup[day] || ""}
                          </td>
                        ))}
                        <td>{item?.totalAttendance}</td>
                      </tr>
                    )}
                  )}
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
      </Container>
      <div style={{marginLeft:'90%', fontWeight:'bold'}}>
        {'Total -  '}
        {attendanceTable.reduce((total, item) => total + item.totalAttendance, 0)}
      </div>
      <Footer/>
    </>
  );
}

export default EmployeeAttendance;
