import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useHistory } from "react-router-dom";
import Header from '../Header';
import Footer from '../Footer';
import { Button, TextField, CircularProgress, MenuItem, Select, Pagination } from "@mui/material";
import { Box } from "@mui/system";
import { useSelector, useDispatch } from "react-redux";
import { fetchSiteData } from '../../actions/index';
import _ from 'lodash';

const SiteTable = () => {
  const [sites, setSites] = useState([]);
  const [filteredSites, setFilteredSites] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSiteData());
  }, []);

  const { loading, siteData } = useSelector(state => state);

  useEffect(() => {
    setSites(siteData.siteData);
    setFilteredSites(siteData.siteData);
  }, [siteData]);

  const handleSearch = _.debounce((query) => {
    const lowercasedQuery = query.toLowerCase();
    const filtered = sites.filter((site) => {
      return (
        site.compliance.toLowerCase().includes(lowercasedQuery) ||
        site.siteName.toLowerCase().includes(lowercasedQuery) ||
        site.siteId.toLowerCase().includes(lowercasedQuery) ||
        site.isActive.toString().toLowerCase().includes(lowercasedQuery)
      );
    });
    setFilteredSites(filtered);
    setCurrentPage(1); // Reset to the first page after search
  }, 300); // Adjust debounce delay as needed

  // Update search query and trigger search
  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    handleSearch(query); // Debounced search function
  };

  const handleEdit = (siteId) => {
    history.push({
      pathname: `/site/editSite`,
      state: siteId,
    });
  };

  // Calculate the index range for the current page
  const indexOfLastSite = currentPage * rowsPerPage;
  const indexOfFirstSite = indexOfLastSite - rowsPerPage;
  const currentSites = filteredSites.slice(indexOfFirstSite, indexOfLastSite);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1); // Reset to the first page when changing the number of rows per page
  };

  return (
    <>
      <Header />
      <div className="text-field-page">
        <div className="search-header">
          <h1>All Sites</h1>
          <TextField
            id="search"
            label="Search"
            variant="outlined"
            size="small"
            value={searchQuery}
            onChange={handleSearchChange}
            placeholder="Search by Site Id, Site Name, etc."
            style={{ marginLeft: "auto", marginRight: "10px" }}
          />
        </div>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress size={25} color="primary" />
          </Box>
        ) : (
          <table className="table table-striped">
            <thead>
              <tr>
                <th>S No.</th>
                <th>Site ID</th>
                <th>Site Name</th>
                <th>Total Employees</th>
                <th>Estimated Cost Per Month</th>
                <th>Compliance</th>
                <th>GST Percentage</th>
                <th>Is Active</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentSites.map((site, index) => (
                <tr key={site.siteId}>
                  <td>{indexOfFirstSite + index + 1}</td>
                  <td>{site.siteId}</td>
                  <td>{site.siteName}</td>
                  <td>{site.totalEmployees}</td>
                  <td>{site.estimatedCostPerMonth}</td>
                  <td>{site.compliance}</td>
                  <td>{site.gstPercentage}</td>
                  <td>{site.isActive ? "Yes" : "No"}</td>
                  <td>
                    <Button
                      variant="contained"
                      onClick={() => handleEdit(site.siteId)}
                    >
                      Edit
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        <div className="pagination-container">
          <div className="rows-per-page">
            <span>Rows per page: </span>
            <Select
              value={rowsPerPage}
              onChange={handleRowsPerPageChange}
              style={{ marginLeft: "10px" }}
            >
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
          </div>
          <Pagination
            count={Math.ceil(filteredSites.length / rowsPerPage)}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
            style={{ marginLeft: "auto" }}
          />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SiteTable;
