import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSnackbar } from "notistack";
import { config } from "../../App";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import Header from "../Header";
import Footer from '../Footer';
import "./Attendance.css";
import { updateAttendance, deleteAttendance, fetchEmployeeData, fetchSiteData } from "../../actions";

function Attendance() {
  const { siteData, employeeData, attendanceData } = useSelector(state => state);
  const [attendance, setAttendance] = useState([]);
  const [headerValues, setHeaderValues] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState();
  const token = localStorage.getItem("token");

  const { enqueueSnackbar } = useSnackbar();

  useEffect(()=>{
    dispatch(fetchEmployeeData());
    dispatch(fetchSiteData());
  },[])

  const history = useHistory();
  const dispatch = useDispatch();

  function handleHeaderInput(e) {
      const { name, value } = e.target;
      setHeaderValues({ ...headerValues, [name]: value });
  }

  const fetchAttendanceData = async () => {
    if (!headerValues.siteId || !headerValues.attendanceDate) return;
    let employeeSiteWise = employeeData.employeeData.filter(emp=>
      emp.siteId === headerValues?.siteId && emp.isActive === true
    )?.map(att=>({
      empId: att.empId,
      siteId: headerValues.siteId,
      attendanceDate: headerValues.attendanceDate,
    }))

    try {
      setLoading(true);
      await axios.get(`${config.endpoint}attendance/getAttendance`, {
        params: {
          siteId: headerValues.siteId,
          month: headerValues.attendanceDate.slice(0, 7)},
        headers: {'Authorization': `Bearer ${token}`}
      }).then(response => {
          let existingAttendance = employeeSiteWise.map(filteredItem => {
            const matchingResponseItem = response?.data?.find(respItem => respItem?.empId === filteredItem?.empId);
            if (matchingResponseItem) {
              const matchingAttendance = matchingResponseItem.attendance.find(attendanceRecord =>
                attendanceRecord.attendanceDate.slice(0, 10) === filteredItem?.attendanceDate
              );
              return {
                ...filteredItem,
                dayReport: matchingAttendance?.dayReport || ""
              }
            }else{
              return {
                ...filteredItem,
              }
            }
          });

          const getUniqueItems = (sourceArr, compareArr) => {
            return sourceArr.filter(item1 => 
              !compareArr.some(item2 => item1?.empId === item2?.empId)
            );
          };
      
          let uniqueEmp = getUniqueItems(response.data, employeeSiteWise);
      
          let additionalEmployee = uniqueEmp.flatMap(unique => 
            unique.attendance.map(att => {
              if (att.attendanceDate.slice(0, 10) === headerValues.attendanceDate) {
                return {
                  empId: unique?.empId,
                  siteId: headerValues?.siteId,
                  attendanceDate: headerValues?.attendanceDate,
                  dayReport: att?.dayReport
                };
              }
              return null;
            })
          ).filter(record => record !== null);
          setAttendance([...existingAttendance, ...additionalEmployee])
      })
      setLoading(false);
    } catch (e) {
      setLoading(false);
      enqueueSnackbar("Failed to fetch attendance data " + e, { variant: "error" });
    }
  };

  useEffect(()=>{
    fetchAttendanceData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[headerValues])

  function handleUserInput(e, item) {
    const updatedAttendance = attendance.map(obj => {
      if (obj?.empId === item?.empId) {
        return {  ...obj, dayReport: e.target.value }
      }
      return obj;
    });
    setAttendance(updatedAttendance);
  }

  const deleteAtt = async(index, item) => {
    try {
      const response = await axios.delete(`${config.endpoint}attendance/`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: {
          empId: item.empId,
          attendanceDate: headerValues.attendanceDate,
          siteId: item.siteId 
        },
      });

      if (response.status === 204) {

        enqueueSnackbar("Attendance Deleted", { variant: "success" });
        dispatch(deleteAttendance(item.empId, `${headerValues.year}-${headerValues.month}-${index + 1 < 10 ? "0" + (index + 1) : index + 1}`, item.siteId));
      }
    } catch (e) {
      if (e.response && e.response.status === 400) {
        enqueueSnackbar(e.response.data.message, { variant: "error" });
      } else {
        enqueueSnackbar("Something went wrong in the backend", { variant: "error" });
      }
    }
  }

  const handleReset = async (index, item) => {
    setAttendance(prevState => {
      const newState = prevState.map(obj => {
        if (obj?.empId === item?.empId) {
          return { ...obj, dayReport: '' }; 
        }
        return obj;
      });
  
      const isSame = prevState.every((obj, i) => obj.dayReport === newState[i].dayReport);
      if (isSame) {
        deleteAtt(index, item);
        return prevState;
      } else {
        return newState;
      }
    });
  };

  function markAllAttendance(status) {
    const updatedForm = attendance.map(emp => ({
      ...emp,
      dayReport: status
    }));
    setAttendance(updatedForm);
  }

  const validateInput = (headerValues, attendance) => {
    if (!headerValues.attendanceDate) {
      enqueueSnackbar("Attendance Date is a required field", { variant: "warning" });
      return false;
    }
    const hasEmptyDayReport = attendance.every(item => 
      !item.dayReport
    );
    
    if(hasEmptyDayReport){
      enqueueSnackbar("Please mark atleast one Attendance", { variant: "warning" });
      return false;
    }
    return true;
  };

  const postAttendanceData = async (formValues) => {
    if (!validateInput(headerValues, attendance)) { return; }

    const filteredFormValues = formValues.filter(item => item.empId && item.dayReport);;
    try {
      setLoading(true);
      const response = await axios.put(`${config.endpoint}attendance/dailyAttendance`, filteredFormValues, {
        headers: {
          'content-type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
  
      setLoading(false);
      if (response.status === 200) {
        enqueueSnackbar("Attendance Marked", { variant: "success" });
        dispatch(updateAttendance(response.data));
      }
    } catch (e) {
      setLoading(false);
      if (e.response && e.response.status === 400) {
        enqueueSnackbar(e.response.data.message, { variant: "error" });
      }
    }
  };

  function handleEmployeeSelect(e) {
    setSelectedEmployee(e.target.value);
    
    const filtered = employeeData.employeeData.filter(emp => emp.empId === e.target.value && emp?.isActive === true)[0]; 
    setAttendance(prevData => [
      ...prevData,
      {
        empId: filtered?.empId,
        siteId: headerValues?.siteId,
        attendanceDate: headerValues?.attendanceDate,
        dayReport: ''
      }
    ]);
  }

  return (
    <>
      <Header />
      <Container fluid>
        <Row className="mt-3">
          <Col xs={12} md={4}>
            <h3 className="mb-3">Select Site</h3>
            <Form.Control as="select" name="siteId" value={headerValues?.siteId} onChange={handleHeaderInput}>
              <option value="">Select an option</option>
              {siteData?.siteData.filter((site) => site?.isActive).map(x=>
                <option value={x.siteId} key={x.siteId}>{x.siteName} - {x.siteId}</option>)}
            </Form.Control>
          </Col>
          <Col xs={12} md={4}>
            <h3 className="mb-3">Date</h3>
            <Form.Control type="date" name="attendanceDate" value={headerValues?.attendanceDate} onChange={handleHeaderInput} />
          </Col>
          <Col xs={12} md={4}>
            <Button variant="success" onClick={() => markAllAttendance('P')} style={{ margin: '10px' }}>
              Mark All Present
            </Button>
           <Button variant="danger" onClick={() => markAllAttendance('A')} style={{ margin: '10px' }}>
             Mark All Absent
           </Button>
          </Col>
        </Row>
      </Container>
      <div className="table-responsive">
        <table className="table table-bordered table-striped">
          <thead>
            <tr>
              <th style={{ width: '120px' }}>S.No.</th>
              <th style={{ width: '120px' }}>Employee ID</th>
              <th style={{ width: '200px' }}>Name</th>
              <th style={{ width: '200px' }}>Working Site</th>
              <th style={{ width: '250px' }}>Day Report</th>
            </tr>
          </thead>
          <tbody>
            {attendance?.map((item, i) => (
              <tr key={i}>
                <td>{i + 1}</td>
                <td>{item?.empId}</td>
                <td>{employeeData.employeeData.find(emp => item?.empId === emp.empId)?.empName}</td>
                <td>{siteData.siteData.find(site => item?.siteId === site.siteId)?.siteName}</td>
                <td>
                  <div className="d-flex flex-column flex-md-row justify-content-md-between">
                    <div className="btn-group" role="group">
                      <Button variant={item?.dayReport === 'P' ? "success" : "secondary"} onClick={(e) => handleUserInput(e, item)} name="dayReport" value="P">Present</Button>
                      <Button variant={item?.dayReport === 'A' ? "danger" : "secondary"} onClick={(e) => handleUserInput(e, item)} name="dayReport" value="A">Absent</Button>
                      <Button variant={item?.dayReport === 'H' ? "warning" : "secondary"} onClick={(e) => handleUserInput(e, item)} name="dayReport" value="H">Half Day</Button>
                      <Button variant={item?.dayReport === 'D' ? "primary" : "secondary"} onClick={(e) => handleUserInput(e, item)} name="dayReport" value="D">Double</Button>
                      <Button variant={item?.dayReport === 'PL' ? "success" : "secondary"} onClick={(e) => handleUserInput(e, item)} name="dayReport" value="PL">Paid Leave</Button>
                      <Button variant='light' name='reset' onClick={(e)=> handleReset(i, item)}>Reset</Button>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
        <h6 style={{marginRight:'77%'}}>Select Employee from other site</h6>
        <Form.Control as="select" style={{width:'20%'}} value={selectedEmployee} onChange={handleEmployeeSelect}>
          <option value="">Select Employee</option>
          {employeeData.employeeData.map((emp) => {
          const isPresentInFilteredData = attendance?.some(filteredEmp => ((filteredEmp?.empId === emp?.empId && emp.isActive)));
          if (!isPresentInFilteredData) {
            <option key='' value="Please Select Employee">{`${emp.empName} - ${emp.empId}`}</option>
            return (
              <option key={emp.empId} value={emp.empId}>{`${emp.empName} - ${emp.empId}`}</option>
            );
          }
          return null;
        })}
        </Form.Control>
        <div className="text-center">
          <Button variant="success" onClick={() => postAttendanceData(attendance)} disabled={loading}>
            {loading ? "Saving..." : "Save Attendance"}
          </Button>
        </div>

      <Footer />
    </>
  );
}

export default Attendance;
