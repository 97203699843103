import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button, TextField, MenuItem, Select, Pagination, CircularProgress } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Box } from "@mui/system";
import Header from "../Header";
import Footer from "../Footer";
import "./EmployeeDetails.css";
import { fetchEmployeeData, fetchSiteData } from '../../actions/index';

const EmployeeTable = () => {
  const [employees, setEmployees] = useState([]);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(()=>{
    dispatch(fetchEmployeeData());
    dispatch(fetchSiteData());
  },[])

  const { loading, employeeData } = useSelector((state) => state);

  useEffect(() => {
    setEmployees(employeeData.employeeData);
    setFilteredEmployees(employeeData.employeeData);
  }, [employeeData]);

  const handleEdit = (empId) => {
    history.push({
      pathname: `/employee/editEmployee`,
      state: empId,
    });
  };

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    const filtered = employees.filter((employee) => {
      return (
        employee.empName.toLowerCase().includes(query) ||
        employee.phone.includes(query) ||
        employee.empId.toLowerCase().includes(query) ||
        employee.siteName.toLowerCase().includes(query) ||
        employee.siteId.toLowerCase().includes(query) ||
        employee.gender.toLowerCase().includes(query)
      );
    });
    setFilteredEmployees(filtered);
    setCurrentPage(1);
  };

  const indexOfLastEmployee = currentPage * rowsPerPage;
  const indexOfFirstEmployee = indexOfLastEmployee - rowsPerPage;
  const currentEmployees = filteredEmployees.slice(indexOfFirstEmployee, indexOfLastEmployee);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  return (
    <>
      <Header />
      <div className="text-field-page">
        <div className="search-header">
          <h1>Employee List</h1>
          <TextField
            id="search"
            label="Search"
            variant="outlined"
            size="small"
            value={searchQuery}
            onChange={handleSearch}
            placeholder="Search by name, phone, ID, site, etc."
            style={{ marginLeft: "auto", marginRight: "10px" }}
          />
        </div>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress size={25} color="primary" />
          </Box>
        ) : (
        <table className="table table-striped">
          <thead>
            <tr>
              <th>S No.</th>
              <th>Employee Id</th>
              <th>Employee Name</th>
              <th>Gender</th>
              <th>Phone</th>
              <th>Designation</th>
              <th>Salary</th>
              <th>Joining Date</th>
              <th>Site Name</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {currentEmployees?.map((employee, index) => (
              <tr key={employee.empId}>
                <td>{indexOfFirstEmployee + index + 1}</td>
                <td>{employee.empId}</td>
                <td>{employee.empName}</td>
                <td>{employee.gender}</td>
                <td>{employee.phone}</td>
                <td>{employee.designation}</td>
                <td>{employee.salary}</td>
                <td>{employee?.joiningDate?.split("T")[0]}</td>
                <td>{employee.siteName}</td>
                <td>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleEdit(employee.empId)}
                  >
                    Edit
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        )}
        <div className="pagination-container">
          <div className="rows-per-page">
            <span>Rows per page: </span>
            <Select
              value={rowsPerPage}
              onChange={handleRowsPerPageChange}
              style={{ marginLeft: "10px" }}
            >
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
          </div>
          <Pagination
            count={Math.ceil(filteredEmployees.length / rowsPerPage)}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
            style={{ marginLeft: "auto" }}
          />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default EmployeeTable;
